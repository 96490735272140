import React from "react";
import { LoadingSpinner, PageLayout } from "shared";
import certificateGraphics from "assets/images/certificate-content-graphics.svg";
import noCertificateGraphics from "assets/images/no-certificate-graphics.svg";
import { useCertificate } from "..";
import { useParams } from "react-router-dom";
import moment from "moment";

export const CertificateDetails = () => {
  const { id } = useParams();

  const { certificateInfoQuery } = useCertificate({
    load: true,
    id,
  });

  const { data: certificate, isLoading, isError } = certificateInfoQuery;

  if (isLoading) return <LoadingSpinner />;

  return (
    <PageLayout>
      <section className="section main-section">
        <div className="container">
          <div className="row">
            {isError ? (
              <div class="col-12">
                <div class="content-error-section">
                  <img class="error-image" src={noCertificateGraphics} alt="" />
                  <h2 class="error-title">No Certificate found!</h2>
                  <p class="error-description">
                    There is no certificate available at the moment.
                  </p>
                </div>
              </div>
            ) : (
              <div className="content-section ps-2 pe-2">
                <h2 className="content-title mb-4">
                  Certificate Successfully Verified
                </h2>
                <div className="col-12">
                  <div className="boxed-view-content d-flex flex-wrap justify-content-start">
                    <div className="col-12 col-md-5 boxed-left-block">
                      <div className="graphics-bg h-100 w-100 d-flex">
                        <img
                          className="m-auto"
                          src={certificateGraphics}
                          alt="graphics"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-7 boxed-right-block">
                      <div className="data-table pt-4 pb-4 ps-3">
                        <div className="row align-items-start pt-2 pb-2">
                          <div className="td col-4">
                            <span className="table-label">Registration No</span>
                          </div>
                          <div className="td col-8">
                            <span className="table-value">
                              {certificate?.data?.registrationNo}
                            </span>
                          </div>
                        </div>
                        <div className="row align-items-start pt-2 pb-2">
                          <div className="td col-4">
                            <span className="table-label">Student Name</span>
                          </div>
                          <div className="td col-8">
                            <span className="table-value">
                              {certificate?.data?.issuedTo}
                            </span>
                          </div>
                        </div>
                        <div className="row align-items-start pt-2 pb-2">
                          <div className="td col-4">
                            <span className="table-label">Term/ Batch</span>
                          </div>
                          <div className="td col-8">
                            <span className="table-value">
                              {certificate?.data?.term}
                            </span>
                          </div>
                        </div>
                        <div className="row align-items-start pt-2 pb-2">
                          <div className="td col-4">
                            <span className="table-label">Course/Program</span>
                          </div>
                          <div className="td col-8">
                            <span className="table-value">
                              {certificate?.data?.courseName}
                            </span>
                          </div>
                        </div>
                        <div className="row align-items-start pt-2 pb-2">
                          <div className="td col-4">
                            <span className="table-label">Issued by</span>
                          </div>
                          <div className="td col-8">
                            <span className="table-value">
                              <div className="association-logo">
                                <span className="title-span">
                                  {certificate?.data?.issuer?.name}
                                </span>
                                {certificate?.data?.issuer?.logoURL && (
                                  <img
                                    src={certificate?.data?.issuer?.logoURL}
                                  />
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="row align-items-start pt-2 pb-2">
                          <div className="td col-4">
                            <span className="table-label">Issued Date</span>
                          </div>
                          <div className="td col-8">
                            <span className="table-value">
                              {moment(certificate?.data?.issuedOn).format(
                                "DD-MMM-yyyy"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="row align-items-start pt-2 pb-2">
                          <div className="td col-4">
                            <span className="table-label">Institute</span>
                          </div>
                          <div className="td col-8">
                            <span className="table-value">
                              {certificate?.data?.otherInfo?.Institute || "__"}
                            </span>
                          </div>
                        </div>
                        <div className="row align-items-start pt-2 pb-2">
                          <div className="td col-4">
                            <span className="table-label">In Association</span>
                          </div>
                          <div className="td col-8">
                            <span className="table-value">
                              <div className="association-logo">
                                <span className="title-span">
                                  {certificate?.data?.otherInfo
                                    ?.InAssociation || "__"}
                                </span>
                                {certificate?.data?.otherInfo?.logoURL && (
                                  <img
                                    src={certificate?.data?.otherInfo?.logoURL}
                                  />
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </PageLayout>
  );
};
