import React from "react";
import { PageLayout } from ".";

export const PageNotFound = () => {
  return (
    <PageLayout>
      <div className="page-not-found">
        <h2 className="not-found-title">404 | Page not found.</h2>
      </div>
    </PageLayout>
  );
};
