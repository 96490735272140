import React from "react";
import { Link } from "react-router-dom";
import appInfo from "../../../package.json";

export const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="container">
        <div className="main-footer-block d-flex justify-content-between align-items-center">
          <span className="app-version">{`v${appInfo.version}`}</span>
          <span className="copyright-text">©NERGY INDIA PVT LTD</span>
        </div>
      </div>
    </footer>
  );
};
