import React from "react";
import { PageLayout } from "shared";
import landingGraphics from "assets/images/landing-graphics.svg";

export const CertificateLanding = () => {
  return (
    <PageLayout>
      <section className="section landing-section">
        <div className="landing-wrapper">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-md-6">
                <div className="landing-block">
                  <div className="landing-graphics">
                    <img src={landingGraphics} alt="landing graphics" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="landing-block">
                  <div className="landing-content">
                    <h1 className="landing-heading">
                      How to know your certificate is valid?
                    </h1>
                    <ul className="landing-list">
                      <li>Scan the QR code on the certificate</li>
                      <li>It will take you to a link - access it</li>
                      <li>
                        The link will takes you to a page with all the details
                        if it's a valid certificate
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};
