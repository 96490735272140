import React from "react";
import nergyLogo from "assets/images/nergy-logo-inline.svg";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <header className="main-header">
      <div className="container">
        <div className="header-block text-center">
          <Link to="/">
            <img
              className="brand-logo"
              src={nergyLogo}
              alt="nergy vidya logo"
            />
          </Link>
        </div>
      </div>
    </header>
  );
};
