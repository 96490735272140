import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CertificateDetails, CertificateLanding } from "..";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PageNotFound } from "shared";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const CertificateVerification = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CertificateLanding />} />
          <Route path="/certificate/:id" element={<CertificateDetails />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
