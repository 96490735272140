import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getCertificateInfo, GET_CERTIFICATE_INFO } from "..";

export const useCertificate = ({ load = false, id = null }) => {
  const certificateInfoQuery = useQuery({
    queryKey: [GET_CERTIFICATE_INFO, id],
    queryFn: () => getCertificateInfo(id),
    enabled: load && !!id,
  });

  return {
    certificateInfoQuery,
  };
};
