import React from "react";
import ReactDOM from "react-dom/client";
import { CertificateVerification } from "modules";
import "assets/styles/styles.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CertificateVerification />
  </React.StrictMode>
);
